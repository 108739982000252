import React, { useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { teacher_login_bg } from "../../assets/images/images";  // Your logo path
import { useAuth } from "../../features/auth/AuthContext";  // Assuming you have an auth context
import GoogleInUp from "../../pages/GoogleInUp";  // Import GoogleInUp component
import "../../index.css";
import "./RegisterForm.css";
import { formatApiErrors } from "../../utils/formatApiErrors";  // Utility for error formatting

const LoginwithGoogle = () => {
  const [loading, setLoading] = useState(false);
  const [checkValidationRegisterIs, setCheckValidationRegisterIs] = useState(false);
  const { login } = useAuth();  // Assuming you're using AuthContext
  const navigate = useNavigate();
  
  
  localStorage.removeItem('emailId')


  const handleSignUpClick = (event) => {
    event.preventDefault();
    navigate("/signup")
  };

  const CodeVerification = (event) => {
    event.preventDefault();
    navigate("/email-verification", { state: { status: 'Login' } });  // Redirect for code verification
  };

  return (
    <div className="register-main-section">
      <div className="login-mani-wrapper">
        <form action="" className="register-left-section">
          <div
            className="back-home-btn"
            onClick={() => {
              navigate("/");
            }}
          >
            <span>
              <i className="fa-solid fa-chevron-left"></i>
            </span>
            <span>Home</span>
          </div>
          <div className="Register-container-wrapper">
            <h2 onClick={() => navigate("/")}>
              Welcome to <span>SRDS.ai</span>
            </h2>
            {checkValidationRegisterIs && (
              <p className="regiterAsNote">Please select login as Student or Teacher.</p>
            )}
            <div className="login-with-page-section">
              <div className="login-with-page-folder">
                {/* Use the GoogleInUp component */}
                <GoogleInUp
                  forContent="Login with Google"
                  loading={loading}
                  setLoading={setLoading}
                  setRoleValid={setCheckValidationRegisterIs}
                  login={login}  // Handle successful login
                />
              </div>
            </div>
            <span className="or-divider">or</span>
            <div className="login-with-Verification-code" onClick={CodeVerification}>
              <p>Login with Verification Code</p>
            </div>
          </div>
          <div className="Already-have-an-accout-wrapper">
            <p className="terms-and-Privacy-policy">
              By Logging In Saddle River Day School, you agree to our terms of use & Privacy policy
            </p>
            <div className="Already-have-an-accout-folder">
              <span>
                Don't have an account?{" "}
                <a href="#" onClick={handleSignUpClick}>
                  Sign up
                </a>
              </span>
            </div>
          </div>
        </form>
        <div className="register-right-section">
          <div className="signup-image">
            <img src={teacher_login_bg} alt="Google login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginwithGoogle;



