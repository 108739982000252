import React, { useEffect, useState } from "react";
import "./MultipleAssignment.css";
import QuestionNew from "../../pages/home/components/questions/Question_new";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import Helper from "../../utils/Helper";
import StudentAssignmentScore from "../../pages/home/components/dashboard/student/lessons/StudentAssignmentScore";
import CongratulationsPopup from '../../components/CongratulationsPopup/CongratulationsPopup';
import MultipleAssignmentStartAssignment from "../../../src/components/MultiplePractiseAssignments/MultipleAssignmentStartAssignment";
import {
    check_image
} from "../../assets/images/images";


function MultipleAssignmentviewResult({ index, assignment, startAssignmentPopupHandler, lesson, course, rubrics, setcallWebSocket, callWebSocket, setwebsocketPayload, assignmentResultHandler }) {

    const [detailedResult, setdetailedResult] = useState(false);
    const [assignmentIndex, setAssignmentIndex] = useState(0);
    const [arrayIndex, setArrayIndex] = useState(0);
    const [showScorePopup, setshowScorePopup] = useState(false);
    const [showCongratulations, setShowCongratulations] = useState(false);
    const [filteredAssignments, setfilteredAssignments] = useState([])
    const practiceAssignments = useSelector((state) => state.getPracticeAssignments);
    const globalState = useSelector((state) => state.globalState);



    useEffect(() => {
        if (showCongratulations) {
            setTimeout(() => {
                setShowCongratulations(false)
            }, 2000)
        }

    }, [showCongratulations])

    useEffect(() => {
        const uniqueIds = new Set();
        const filteredAssign = [];

        // Iterate backwards through the array to capture the last occurrences
        for (let i = practiceAssignments?.practiceassignments.length - 1; i >= 0; i--) {
            const practiceassignment = practiceAssignments.practiceassignments[i];
            if (practiceassignment.assignment_id === assignment?.assignment_id && !uniqueIds.has(practiceassignment.id)) {
                // if (practiceassignment.assignment_id === assignment?.assignment_id ) {
                uniqueIds.add(practiceassignment.id);
                filteredAssign.push(practiceassignment);
            }
        }

        // Since we processed backwards, reverse to maintain original order
        filteredAssign.reverse();

        setfilteredAssignments(filteredAssign);
    }, [practiceAssignments]);

    return (
        <>
            <div className="maltiple-assignment-card">
                <p className="maltiple-assignment-title"><span>{index + 1}.</span>{assignment?.name}</p>
                <div className="maltiple-assignment-card-date-container">
                    <div className="maltiple-assignment-card-date">
                        <p>Created Date: </p>
                        <p>{Helper.formatDateTime(assignment?.start_date).date}</p>
                    </div>
                    <div className="maltiple-assignment-card-date">
                        {assignment?.submission === null ? (
                            <>
                                <p className="due-date">Due Date:</p>
                                <p>{assignment?.end_date === null ? "-" : Helper.formatDateTime(assignment?.end_date).date}</p>
                            </>
                        ) : (
                            <>
                                <p>Submitted On:</p>
                                <p>{Helper.formatDateTime(assignment?.assignment_completed_at).date}</p>
                            </>
                        )}
                    </div>
                </div>
                {assignment?.assignment_status?.status === "Completed" && assignment?.submission !== null ? <button disabled={assignment?.submission === null} className="maltiple-assignment-btn" onClick={() => { assignmentResultHandler(assignment); setshowScorePopup(!showScorePopup) }} >View Result</button> :
                    <button
                        disabled={
                            (assignment?.assignment_json === null || assignment?.ai_feedback_json === null)
                        }
                        title={assignment?.assignment_json === null || assignment?.ai_feedback_json === null ? "Generating assignment...Please wait!" : ""}
                        onClick={() => startAssignmentPopupHandler(assignment, index)} className="maltiple-assignment-start-btn"> {assignment?.assignment_json === null || assignment?.ai_feedback_json === null ? 'Generating assignment...Please wait!' : 'Start Assignment'}</button>
                }

            </div>

            {
                !globalState.subdomain_exists &&
                (filteredAssignments?.length > 0 ? filteredAssignments.map((practiceassignment, index) => (
                    <MultipleAssignmentStartAssignment key={index} index={index} practiceassignment={practiceassignment} lesson={lesson} course={course} rubrics={rubrics} setcallWebSocket={setcallWebSocket}
                        callWebSocket={callWebSocket} setwebsocketPayload={setwebsocketPayload} assignmentResultHandler={assignmentResultHandler} filteredAssignments={filteredAssignments} />
                ))
                    : <></>)}

            {showScorePopup && (
                <StudentAssignmentScore
                    showScore={() => { }}
                    getSubmissiondtls={assignment?.submission?.ai_evaluation_json}
                    onclick={() => {
                        setshowScorePopup(!showScorePopup)
                    }}
                    title={"Close"}
                    isCurrentAssignment={true}
                    onclick2={() => {
                        setdetailedResult(!detailedResult)
                        setshowScorePopup(!showScorePopup)
                    }}
                    title2={"View Result"}
                    title3={"Review Assignment"}
                    assignment={assignment}
                    setshowScorePopup={setshowScorePopup}
                    setShowCongratulations={setShowCongratulations}
                    setcallWebSocket={setcallWebSocket}
                    callWebSocket={callWebSocket}
                    setwebsocketPayload={setwebsocketPayload}
                    isNaviget={false}
                    filteredAssignments={filteredAssignments}
                />
            )}

            {showCongratulations && <CongratulationsPopup
                check_image={check_image}
                des="Review Assignments has been generated successfully."
            />}


            {detailedResult && (
                <div className="AssignmentViewProgress-mock-test-wrapper">
                    <div
                        className="AssignmentViewProgress-mock-test-container"
                    >
                        {
                            <QuestionNew
                                assignment={
                                    assignment?.submission?.student_answers_json[
                                    assignmentIndex
                                    ]
                                }
                                ai_evaluation_json={
                                    assignment?.submission?.ai_evaluation_json
                                }
                                index={assignmentIndex}
                                arrayIndex={arrayIndex}
                                currentStep={assignmentIndex + 1}
                                totalSteps={
                                    assignment?.submission?.student_answers_json.length
                                }
                                handleNext={() =>
                                    assignmentIndex <
                                        assignment?.submission?.student_answers_json.length - 1
                                        ? setAssignmentIndex((p) => p + 1)
                                        : null
                                }
                                handleBack={() => setAssignmentIndex((p) => p - 1)}
                                isLast={
                                    assignmentIndex ===
                                    assignment?.submission?.student_answers_json.length - 1
                                }
                                isFirst={assignmentIndex === 0}
                                close={
                                    <span
                                        onClick={() => {
                                            setdetailedResult(!detailedResult)
                                            setAssignmentIndex(0)
                                        }}
                                        className="AssignmentViewProgress-mock-test-close-btn"
                                    >
                                        <i className="fa-solid fa-x"></i>
                                    </span>
                                }
                                questions={assignment?.submission?.ai_evaluation_json?.questions}
                            />
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default MultipleAssignmentviewResult;