import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Assistant from "./tabs/Assitant";
import ChangePassword from "./tabs/ChangePassword";
import Profile from "./tabs/Profile";
import BackButton from "../../../../components/BackButton/BackButton";

function Settings({isDashboard}) {

  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const isTeacher = globalState?.role === "Teacher";
  const [activeTab, setActiveTab] = useState("profile");
  const TabClick = (tab) => {
    setActiveTab(tab);
  };

  const TabLink = ({ activeTab, tabName, label, onClick }) => (
    <li>
      <a
        // href="#"
        className={activeTab === tabName ? "active" : ""}
        onClick={() => onClick(tabName)}
        style={{ cursor: "pointer" }}
      >
        {label}
      </a>
    </li>
  );

  function renderComponent(activeTab) {
    switch (activeTab) {
      case "profile":
        return <Profile />;
      case "assistant":
        return <Assistant />;
      case "password":
        return <ChangePassword />;
      default:
        return null; // or some default component if none of the cases match
    }
  }
  return (
    <div className="settings_main_sec">
      <Grid
        container
        justifyContent={isDashboard ? "center" : "space-between"}
        alignItems={"flex-end"}
        xs={12}
      >
        {!isDashboard &&(
          <div className="msg_sec_new_head">
            <BackButton buttonName={"Back"} />
          </div>
        )}
        <div className="settings_main_sec_head">
          <ul>
            <TabLink
              activeTab={activeTab}
              tabName="profile"
              label="Profile"
              onClick={TabClick}
            />
            {globalState?.role === "Teacher" && (
              <TabLink
                activeTab={activeTab}
                tabName="assistant"
                label="AI Assistants"
                onClick={TabClick}
              />
            )}
            {/* <TabLink
              activeTab={activeTab}
              tabName="password"
              label="Manage Password"
              onClick={TabClick}
            /> */}
          </ul>
        </div>
        {!isDashboard && <div></div>}
      </Grid>
      <div>{renderComponent(activeTab)}</div>
    </div>
  );
}

export default Settings;
