import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../features/auth/AuthContext";
import GoogleInUp from "../../pages/GoogleInUp";
import "../../index.css";
import BackButton from "../BackButton/BackButton";
import { teacher_login_bg } from "../../assets/images/images";

const SignUpwithGoogle = () => {
    const [checkvalidationregisteris, setCheckValidationRegister] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    localStorage.removeItem('emailId')
    // Track selected role (Teacher or Student)
    const [selectedRole, setSelectedRole] = useState(null);

    // Loading state for Google sign-in
    const [loading, setLoading] = useState(false);

    // Handle role selection
    const handleSelectRole = (event) => {
        setSelectedRole(event.target.value);
        setCheckValidationRegister(false)
        console.log('Selected Role:', event.target.value);  // Debug to confirm correct role selection
    };

    // Handle the sign-up button click (Google sign-up)
    const handleSignUpClick = (event) => {
        event.preventDefault();
        if (selectedRole) {
            // Navigate to the Google login page with the selected role
            navigate("/email-verification", { state: { role: selectedRole, status: 'Sign Up' } });
        } else {
            setCheckValidationRegister(true); // Display role selection warning
        }
    };

    const handleLoginClick = (event) => {
        event.preventDefault();
        navigate("/login")
    };


    return (
        <div className="register-main-section">
            <div className="login-mani-wrapper">
                <form className="register-left-section">
                    <div className="back-home-btn" onClick={() => navigate("/")}>
                        <span><i className="fa-solid fa-chevron-left"></i></span>
                        <span>Home</span>
                    </div>

                    <div className="Register-container-wrapper">
                        <h2 onClick={() => navigate("/")}>
                            Welcome to <span>SRDS.ai</span>
                        </h2>

                        {checkvalidationregisteris && (
                            <p className="regiterAsNote">
                                Please select singup as Student or Teacher.
                            </p>
                        )}

                        <div className="user-tab-selecting-folder">
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="role-select-label">Select Your Profile *</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={selectedRole}
                                    onChange={handleSelectRole}
                                    label="Role"
                                >
                                    <MenuItem value="Teacher">I am a Teacher</MenuItem>
                                    <MenuItem value="Student">I am a Student</MenuItem>
                                    <MenuItem value="Parent" disabled>
                                        I am a Parent (Coming Soon)
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="login-with-page-section">
                            <div className="login-with-page-folder">
                                <GoogleInUp
                                    forContent="Sign Up with Google"
                                    activeTab={selectedRole}
                                    loading={loading}
                                    setRoleValid={setCheckValidationRegister}
                                    setLoading={setLoading}
                                    login={login}
                                />
                            </div>
                        </div>
                        <span className="or-divider">or</span>
                        <div className="login-with-Verification-code" onClick={handleSignUpClick}>
                            <p>Sign Up with Verification Code</p>
                        </div>
                    </div>

                    <div className="Already-have-an-accout-wrapper">
                        {/* <BackButton buttonName="Back" /> */}
                        <p className="terms-and-Privacy-policy">
                            By Signing Up for Saddel River Day School, you agree to our Terms of use & Privacy policy.
                        </p>
                        <div className="Already-have-an-accout-folder">
                            <span>
                                Already have an account?
                                <a href="#" onClick={handleLoginClick}> Log in</a>
                            </span>
                        </div>
                    </div>
                </form>

                <div className="register-right-section">
                    <div className="signup-image">
                        <img src={teacher_login_bg} alt="Background" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpwithGoogle;

