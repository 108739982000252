import { useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, NavLink as RouterNavLink, useNavigate } from "react-router-dom"; // Renamed to RouterNavLink
import { Link } from 'react-scroll';
import { image1 } from "../../../assets/images/images";

function HomeNavbar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "767"));
  const [opentogglebtn, setopentogglebtn] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const toggleBtn = () => {
    setopentogglebtn(!opentogglebtn);
  };

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setopentogglebtn(false);
    navigate("/");
  
    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById('revolution_sec_new');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Adjust the timeout as needed
  };
  
  const handleContactClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setopentogglebtn(false);
  };

  return (
    <div>
      <nav className="header_main_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="header_main_sec_inner">
                <div className="header_logo">
                <img src={image1} alt="logo" />
                </div>
                <div className="header_menu">
                  <ul>
                    <li>
                      <RouterNavLink to="/">Home</RouterNavLink>
                    </li>
                    <li>
                      <RouterNavLink to="/about">About Us</RouterNavLink>
                    </li>
                    <li>
                      <a href="revolution_sec_new" onClick={handleClick}>
                        Features
                      </a>
                    </li>
                    <li>
                      <Link to="footer-section">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div className="header_login">
                  {/* <NavLink to="/login" className="login_btn">
                    Login
                  </NavLink> */}
                  <NavLink to="/login" className="sign_btn">
                    Login
                  </NavLink>
                  {/* <NavLink to="/signup" className="sign_btn">
                    Sign Up
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isSmallScreen ? (
          <div className="nevbar-toggle-btn" onClick={toggleBtn}>
            <a>
              <i class="fa-solid fa-bars-staggered"></i>
            </a>
          </div>
        ) : (
          <></>
        )}
      </nav>

      {opentogglebtn && (
        <nav className="header_main_sec" id="responsive-main-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="header_main_sec_inner" id="responsive-header">
                  <div className="header_logo">
                    <img src={image1} alt="logo" />
                  </div>
                  <div className="header_menu-container">
                  <div className="header_menu">
                      <ul>
                        <li>
                          <RouterNavLink to="/">Home</RouterNavLink>
                        </li>
                        <li>
                          <RouterNavLink to="/about">About Us</RouterNavLink>
                        </li>
                        <li>
                          <a href="revolution_sec_new" onClick={handleClick}>Features</a>
                        </li>
                        <li>
                          <Link to="footer-section" onClick={handleContactClick}>Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="header_login">
                      <NavLink to="/login" className="login_btn">
                        Login
                      </NavLink>
                      <NavLink to="/signup" className="sign_btn">
                        Sign Up
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-toggle-btn" onClick={toggleBtn}>
            <a>
              <i class="fa-solid fa-x"></i>
            </a>
          </div>
        </nav>
      )}
    </div>
  );
}

export default HomeNavbar;