import StudentViewCourseDetails from "../student/course/StudentViewCourseDetails";
import TeacherViewCourseDetails from "../teacher/course/TeacherViewCourseDetails";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../../../../features/auth/AuthContext";


function ViewCourse() {
  const role = useSelector((state) => state.globalState.role);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useAuth();

  useEffect(() => {
    if (!token) {
      // Redirect to signup and pass the current path for post-signup redirection
      navigate(`/signup?redirectToCourse=/course/${id}/view`);
    }
    else {
      localStorage.removeItem("redirectAfterSignup");
      localStorage.removeItem("redirectToCourse");
    }
  }, [token, id, navigate]);

  return role === 'Student' ? <StudentViewCourseDetails /> : <TeacherViewCourseDetails />;
}

export default ViewCourse;