import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface CourseCheckPublishtoSchoologyState {
  message: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: CourseCheckPublishtoSchoologyState = {
  message: null,
  isLoading: false,
  error: null,
};

const courseCheckPublishtoSchoologyslice = createSlice({
  name: 'courseCheckPublishtoSchoology',
  initialState,
  reducers: {
    setCourseCheckPublishtoSchoology: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetCourseCheckPublishtoSchoology: () => initialState,
  },
});

export const { setCourseCheckPublishtoSchoology, setloading, seterror, resetCourseCheckPublishtoSchoology } = courseCheckPublishtoSchoologyslice.actions;

export default courseCheckPublishtoSchoologyslice.reducer;
