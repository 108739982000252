import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setNotificationList, isloading, seterror, resetNotificationList
} from "../reducers/notificationListReducer";

const getNotificationList = (token: any) => async (dispatch: Dispatch) => {
   dispatch(isloading(true));
   try {
     const result = await api
       .get(
        `/notifications/notifications-list-all/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
       )
       if(result){
        dispatch(isloading(false));
        if (result.data?.status === 200) {
            dispatch(setNotificationList(result?.data?.data));
          }
          else {
            dispatch(seterror(result.data?.status ?? "Something went wrong"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message || "Something went wrong"));
   } 
}

export { getNotificationList, resetNotificationList };



