import React, { useState, useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { FormHelperText, styled } from "@mui/material";
import { isRequired } from "../../utils/validator";
import { useLocation } from "react-router-dom";

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 650px;
  margin-inline: auto;
`;

export default function Otp(props) {
    const [otp, setOtp] = useState('');
    const [emailError, setEmailError] = useState('');
    const [remainingTime, setRemainingTime] = useState(90); // 90 seconds = 1.5 minutes
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const location = useLocation();

    const handleOtpChange = (value) => {
        let error = "";
        error = isRequired(value, "code");

        setEmailError(error);
        setOtp(value);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setIsResendDisabled(false);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Cleanup
    }, [remainingTime]);

    const handleResendClick = () => {
        if (isResendDisabled) return;

        setRemainingTime(298); // Reset timer
        setIsResendDisabled(true);
        props.signupUser(props.email, null);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        if (!/^[0-9]+$/.test(otp)) {
            setEmailError("Verification code must be a number");
            return;
        } else if (otp.length < 6) {
            setEmailError("Verification code must be 6 digits long");
            return;
        }

        props.signupUser(props.email, otp);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (!isResendDisabled) {
                e.preventDefault();
                e.stopPropagation();            // e.preventDefault(); // Prevent page reload
                return;
            }
            handleSubmit(e); // Optionally submit the OTP
            e.preventDefault();
            e.stopPropagation();            // e.preventDefault(); // Prevent page reload
        }
    };


    const handleBack = () => {

        props.setShowOtpComponent(false)
    }

    return (
        <div className="otp-verification-section">
            <div className="verification_code">
                <label>A Verification Code has been sent to your email: {props.email}</label>
                <div onKeyDown={handleKeyDown}>
                    <MuiOtpInputStyled
                        label="6-Digit Code"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        length={6}
                        name="code"
                        TextFieldsProps={{
                            size: "small",
                        }}
                        error={!!emailError}
                        value={otp}
                        onChange={handleOtpChange}
                        sx={{ paddingBottom: '15px', gap: "2px" }}
                    />
                </div>
                {emailError && (
                    <FormHelperText error>
                        {emailError}
                    </FormHelperText>
                )}
                <div className="otp-verification-container">
                    <div className="resend-verification-with-edit-email-folder">
                        {/* <button onClick={() => props.setShowOtpComponent(false)}>Edit Email</button> */}
                        <button onClick={() => handleBack()} >Edit Email</button>
                        <span className="Remaning_timer" onClick={handleResendClick} disabled={isResendDisabled}>
                            <span style={{ color: isResendDisabled && 'gray', cursor: isResendDisabled ? 'not-allowed' : 'pointer' }}>Resend Verification Code</span>
                            <span style={{ display: !isResendDisabled && 'none' }}> in {formatTime(remainingTime)}</span>
                        </span>
                    </div>
                    <div className="signup-btn">
                        <button onClick={handleSubmit} style={{ cursor: !isResendDisabled || props.loding ? 'not-allowed' : 'pointer' }} disabled={!isResendDisabled || props.loding} >
                            {location.state.status}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}
