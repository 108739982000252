import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { student_login_bg } from "../../assets/images/images";
import "../../index.css";
import "./RegisterForm.css";
import BackButton from "../BackButton/BackButton";
import Otp from "./Otp";
import RegisterEmailVerfication from "./RegisterEmailVerfication";
import { useAuth } from "../../../src/features/auth/AuthContext";
import api from "../../../src/api/api";
import { toast } from "react-toastify";
import { formatApiErrors } from "../../utils/formatApiErrors";


const WelcomeToSrdsForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();
    const activeTab = location.state.role
    const [searchParams] = useSearchParams();
    const [loding, setLoding] = useState(false)

    const redirectPath = searchParams.get("redirect");

    // Store the redirect path if it exists in search params
    if (redirectPath) {
        localStorage.setItem("redirectAfterSignup", redirectPath);
    }
    const redirectLink = localStorage.getItem("redirectAfterSignup");

    const redirectCourse = searchParams.get("redirectToCourse");

    if (redirectCourse) {
        localStorage.setItem("redirectToCourse", redirectCourse);
    }

    const redirectToCoursePath = localStorage.getItem("redirectToCourse");


    const handleSignUpClick = (event) => {
        event.preventDefault();

        if (location.state.status === 'Login') {
            navigate("/signup")
        } else {
            navigate("/login")

        }
    };

    const [showOtpComponent, setShowOtpComponent] = useState(false);
    const [userEmail, setUserEmail] = useState(null);




    const handleSendCode = (email) => {
        if (location.state.status === 'Login') {
            loginUser(email)
        } else {
            signupUser(email)
        }
    };

    const signupUser = async (email, otp = null) => {
        setLoding(true)
        const failedErrorMessage =
            "Oops, something went wrong. Please try again later.";
        let role = 2;
        if (activeTab === "Student") {
            role = 1;
        } else if (activeTab === "Teacher") {
            role = 2;
        } else if (activeTab === "Parent") {
            role = 3;
        }


        try {

            const emailConverCase = email.toLowerCase()

            const payload = otp ? { email: emailConverCase, role, otp } : { email: emailConverCase, role };
            const response = await api.post('/auth/register-user/', payload);

            setLoding(false)

            if (response?.status === 400) {
                toast.dismiss();
                const error = formatApiErrors(response?.data);
                toast.error(error || response?.data?.message || failedErrorMessage, {
                    position: toast.POSITION.TOP_CENTER,
                });

                return;
            }
            if (
                response?.data?.status === 200 &&
                response?.data?.message ===
                "Verification code has been sent to your email."
            ) {

                setShowOtpComponent(true);
                setUserEmail(email)
                toast.success("Verification code sent successfully", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }
            if (response?.data?.data?.token === undefined) {

                toast.dismiss();
                const error = formatApiErrors(response?.data);

                toast.error(error || response?.data?.message || failedErrorMessage, {
                    // toast.error('watng', {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            login(response?.data?.data?.token, activeTab, email || "");
            toast.dismiss();
            toast.success("Signup successful.", {
                position: toast.POSITION.TOP_CENTER,
            });
            localStorage.removeItem('emailId')
            if (activeTab === "Student") {

                const { pathname } = location.state || { from: "/dashboard/home" };
                navigate(pathname || "/dashboard/home", {
                    state: { role: activeTab },
                });

            } else if (activeTab === "Teacher") {

                navigate("/dashboard/home", { state: { role: activeTab } });

            } else if (activeTab === "Parent") {
                role = "parent";
            }
        } catch (error) {
            setLoding(false)
            toast.dismiss();
            const errorMessage = formatApiErrors(error?.response?.data);
            toast.error(errorMessage || failedErrorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            // }
        }
    };

    const loginUser = async (email, otp = null) => {
        setLoding(true)

        const failedErrorMessage =
            "Oops, something went wrong. Please try again later.";

        try {

            const emailConverCase = email.toLowerCase()
            const payload = otp ? { email: emailConverCase, otp } : { email: emailConverCase };

            const response = await api.post(
                `/auth/login-user/v2/`,
                payload
            );

            // Add your new condition here


            setLoding(false)
            if (response?.status === 400) {

                toast.dismiss();
                const error = formatApiErrors(response?.data);
                toast.error(error || response?.data?.message || failedErrorMessage, {
                    position: toast.POSITION.TOP_CENTER,
                });

                return;
            }
            if (response?.status === 200) {

                if (
                    response?.data?.message ===
                    "Verification code has been sent to your email."
                ) {
                    // Open EmailVerification modal to input the OTP
                    setShowOtpComponent(true);
                    setUserEmail(email)
                    toast.success("Verification code sent successfully", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    return;
                }
                else {

                    let role = null
                    if (response?.data?.role === 1) {
                        role = 'Student'
                    } else if (response?.data?.role === 2) {
                        role = 'Teacher'
                    } else if (response?.data?.role === 3) {
                        role = 'Parent'
                    } else if (response?.data?.role === 4) {
                        role = 'School Admin'
                    } else if ((response?.data?.role === 5)) {
                        role = 'Super Admin'
                    }



                    login(response?.data?.token, role, email || "");
                    toast.dismiss();
                    toast.success("Login successful.", {
                        position: toast.POSITION.TOP_CENTER,
                    });


                    localStorage.removeItem('emailId')
                    if (role === "Student") {

                        if (redirectLink !== null && redirectLink !== "") {

                            navigate(redirectLink);
                            return;
                        }
                        if (redirectToCoursePath !== null && redirectToCoursePath !== "") {

                            navigate(redirectToCoursePath);
                            return;
                        }

                        const { pathname } = location.state || { from: "/dashboard/home" };
                        navigate(pathname || "/dashboard/home", {
                            state: { role: activeTab },
                        });
                    }
                    else if (role === "Teacher") {
                        navigate("/dashboard/home", { state: { role: activeTab } });
                    }
                    else if (role === "Parent") {
                        role = "parent";
                    }
                }
            }
        } catch (error) {
            setLoding(false)
            toast.dismiss();
            const errorMessage = formatApiErrors(error?.response?.data);
            toast.error(errorMessage || failedErrorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

    }


    const handleBackBTN = () => {
        if (!showOtpComponent) {
            navigate(-1)
        } else {
            setShowOtpComponent(false);
        }
    }
    return (
        <div className="welcome-to-srds-form-section">
            <div className="login-mani-wrapper">
                <form
                    action=""
                    className="register-left-section" z
                // onSubmit={handleSubmit}
                >
                    <div
                        className="back-home-btn"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <span>
                            <i class="fa-solid fa-chevron-left"></i>
                        </span>
                        <span>Home</span>
                    </div>
                    <div className="Register-container-wrapper">
                        <h2 onClick={() => {
                            navigate("/");
                        }}>Welcome to <span>SRDS.ai</span></h2>
                        <div className="login-with-Verification-code">
                            <p>{location.state.status} with Verification Code</p>
                        </div>
                        {/* Conditionally render RegisterEmailVerfication or Otp */}
                        {!showOtpComponent ? (
                            <RegisterEmailVerfication
                                handleSendCode={handleSendCode} // To show Otp after sending the code
                                loding={loding}
                            />
                        ) : (
                            <Otp
                                email={userEmail}
                                signupUser={location.state.status === 'Login' ? loginUser : signupUser}
                                setShowOtpComponent={setShowOtpComponent}
                                loding={loding}
                            />
                        )}
                    </div>
                    <div className="Already-have-an-accout-wrapper">
                        {/* <BackButton buttonName="Back" /> */}
                        <div className='Back_button' onClick={handleBackBTN} style={{ cursor: "pointer", display: "flex", alignItems: "center", color: "#0068ff", cursor: "pointer", marginBottom: "26px" }}>
                            <i class="fa-solid fa-chevron-left"></i>
                            <span style={{ padding: " 0px 5px" }}>Back </span>
                        </div>
                        <p className="terms-and-Privacy-policy">
                            By Logging In Saddle River day school, you agree to our terms of
                            use & Privacy policy
                        </p>
                        <div className="Already-have-an-accout-folder">
                            <span>
                                Don't have an account?{" "}
                                <a href="#" onClick={handleSignUpClick}>
                                    {" "}
                                    {location.state.status === 'Login' ? 'Sign up' : 'Login'}
                                </a>
                            </span>
                        </div>
                    </div>
                </form>
                <div className="register-right-section">
                    <div className="signup-image">
                        <img
                            src={student_login_bg}
                            alt="Background"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeToSrdsForm;