import {
    FormControl,
    FormHelperText,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import "../../index.css";
import "./RegisterForm.css";
import {
    isRequired,
    isValidEmail,
} from "../../../src/utils/validator";

const RegisterEmailVerfication = ({ handleSendCode, loding }) => {
    const [email, setEmail] = useState(localStorage.getItem('emailId') || '');
    const [emailError, setEmailError] = useState('');

    const handleSubmit = () => {
        let errors = '';
        errors = isRequired(email, "email") || isValidEmail(email);

        setEmailError(errors);
        if (errors === '') {
            localStorage.setItem('emailId', email)
            handleSendCode(email);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        let error = '';
        error = isRequired(value, "email") || isValidEmail(value);

        setEmailError(error);
        setEmail(value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission on Enter key
            handleSubmit(); // Call your handleSubmit function if needed
        }
    };

    return (
        <div className="registe-emai-verfication-section">
            <div className="signup-form">
                <div className="signup-input edit-email">
                    <FormControl
                        margin="normal"
                        fullWidth
                        error={!!emailError}
                    >
                        <TextField
                            name="email"
                            size="small"
                            value={email}
                            error={!!emailError}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown} // Handle Enter key
                            InputProps={{
                                sx: {
                                    borderRadius: "35px",
                                    backgroundColor: "#F5F5F5",
                                    padding: "0px",
                                    fontSize: "15px",
                                },
                            }}
                        />
                        <FormHelperText>{emailError}</FormHelperText>
                    </FormControl>
                </div>
                <button type="button" className="registe-emai-verfication-send-Code-btn" onClick={handleSubmit} disabled={loding} style={{ cursor: loding ? 'not-allowed' : 'pointer' }}>Send Code</button>
            </div>
        </div>
    );
};

export default RegisterEmailVerfication;
